html {
  margin-top: 0 !important;
}

* {
  font-family: var(--font-swimply), sans-serif;
  box-sizing: border-box;
}

body {
  background: #fff;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  max-width: 100%;
  -webkit-font-smoothing: subpixel-antialiased !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

a {
  text-decoration: none;
  outline: none;
  color: inherit;
}

input {
  -webkit-appearance: none;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

a.ui-slider-handle-snapping {
  -webkit-transition: none;
  -moz-transition: none;
}

.StripeElement {
  border: 1px solid transparent;
}
.StripeElement--focus {
  border: 1px solid #00b2eb;
}
.StripeElement.StripeElement--invalid {
  border: 1px solid #ff0000;
}
@media (max-width: 767px) {
  .ratingNew svg {
    width: 35px !important;
    height: 35px !important;
  }
}

.open-in-app-container {
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid lightgrey;
}

:root {
  --color-brand-primaryBlue: #00B2FF;
  --color-brand-darkBlue: #00538E;
  --color-brand-grayBlue: #868F9D;
  --color-brand-darkGray: #303337;
  --color-brand-hoopsOrange: #F86848;
  --color-brand-tennisGreen: #00AF54;
  --color-brand-yardGreen: #036D19;
  --color-brand-homesPink: #C7016C;
  --color-brand-darkOrange: #C32320;
  --color-text-primary: #1C2022;
  --color-text-secondary: #626A75;
  --color-text-tertiary: #A8AFB8;
  --color-text-quaternary: #8D8C8E;
  --color-text-pentenary: #CCE7F1;
  --color-text-gray1: #CDD1D6;
  --color-text-gray2: #E9EBEE;
  --color-text-gray3: #F5F5F5;
  --color-text-white: #FFFFFF;
  --color-text-blue: #00B2FF;
  --color-text-darkBlue: #00538E;
  --color-text-red: #F86848;
  --color-text-green: #00AF54;
  --color-text-mainDark: #2C2D34;
  --color-text-hint: rgba(0, 0, 0, 0.38);
  --color-text-selectPass: #3F78A1;
  --color-text-yellow: #F7BE18;
  --color-background-primary: #FFFFFF;
  --color-background-secondary: #FCFCFC;
  --color-background-tertiary: #1F1F1F;
  --color-background-quarternary: #F0F3F9;
  --color-background-darkBlue: #02A7DB;
  --color-background-darkBlue2: #0081A6;
  --color-background-earlyBirdPass: #00538E33;
  --color-background-anyTimePass: #3A3E43;
  --color-background-weekdayPass: #007099;
  --color-background-rebook: #EDF3F7;
  --color-background-nonSelectedSwipePass: #CAE5F6;
  --color-background-skyBlue: #F2FBFE;
  --color-background-skyBlue2: #E5F7FD;
  --color-background-skyBlue3: #EDF8FF;
  --color-background-skyBlue4: #00B2FF14;
  --color-background-skyBlue5: #EBF9FD;
  --color-background-warning: #FEF1EF;
  --color-background-lightGray: #F4F5F6;
  --color-background-anyTimeAvailable: #001D48;
  --color-background-weekdayAvailable: #00538E;
  --color-background-earlyBirdAvailable: #00B2FF;
  --color-background-promocodes: #0079AD;
  --color-common-alternateBackground: #E5E5E5;
  --color-common-bare: #FEF7F0;
  --color-common-black: #333333;
  --color-common-blueBorder: #B7E9FF;
  --color-common-blueSkies: #ECFBFF;
  --color-common-border: #E3E3E3;
  --color-common-border2: #D9D9D9;
  --color-common-cloudy:  #FAFBFD;
  --color-common-darkBlue: #0071AA;
  --color-common-darkerGray: #333333;
  --color-common-darkestGray: #191919;
  --color-common-darkgray: #7b858b;
  --color-common-darkPrimary: #00A1CD;
  --color-common-deepEnd: #00648E;
  --color-common-disabled: #ccc;
  --color-common-error: #f86848;
  --color-common-featuredPromo: #00C028;
  --color-common-ghostWhite: #f8f8fa;
  --color-common-gray: #f3f5f5;
  --color-common-grey: #BEBEBE;
  --color-common-guestBlue: #14bef3;
  --color-common-guestDarkerBlue: #0096eb;
  --color-common-hostDarkerGreen: #00EBC1;
  --color-common-hostGreen: #03D98C;
  --color-common-lightBlue: #E0F8FF;
  --color-common-lightBorder: #f2f2f2;
  --color-common-lighterBlue: #81DFFD;
  --color-common-lighterGray: #F3F7FC;
  --color-common-lightestGray: #F4F4F4;
  --color-common-lightGray: #e8e8e8;
  --color-common-lightGreen: #E7FCF1;
  --color-common-lightPrimary: #D3F2FB;
  --color-common-lightPrimaryGreen: #e6f7e6;
  --color-common-lightSecondary: #ffd8ce;
  --color-common-lightText: #687278;
  --color-common-mediumGray: #b3b3b3;
  --color-common-midBlack: #545454;
  --color-common-middleLighterDarkGray: #F8F9FA;
  --color-common-natureGreen: #EFFEED;
  --color-common-neutralHof19: #222222;
  --color-common-nightSwim: #142039;
  --color-common-normalDarkGray: #717171;
  --color-common-normalGray: #EBEBEB;
  --color-common-notification: #FF3838;
  --color-common-pitchBlack: #000000;
  --color-common-placeholder: #f6f6f6;
  --color-common-rose: #F84887;
  --color-common-secondary: #FC5622;
  --color-common-secondaryDarkGreen: #036d19;
  --color-common-slider: #d7e1ea;
  --color-common-solidBlack: #000000;
  --color-common-success: #26b12f;
  --color-common-summerSun: #F86848;
  --color-common-sunny: #F8D148;
  --color-common-superLightGray: #F7F8F9;
  --color-common-tanned: #F9D8B5;
  --color-common-transparent: transparent;
  --color-common-white: #FFFFFF;
  --screens-maxWithPadding: 1560px;
  --screens-max: 1440px;
  --screens-laptop: 1200px;
  --screens-mediumLaptop: 1050px;
  --screens-smallLaptop: 992px;
  --screens-tablet: 768px;
  --screens-phone: 576px;
  --screens-smallPhone: 375px;
  --headerPaddingViewportWidth: max(18px, 3vw);
  --headerPadding: max(18px, 3%);
  --zIndex-negative: -1;
  --zIndex-none: 0;
  --zIndex-base: 1;
  --zIndex-postReservationReview: 2;
  --zIndex-timeSelectorDisabledTooltip: 2;
  --zIndex-becomeAHostCtaChildren: 2;
  --zIndex-becomeAHostHeroWrapperChildren: 2;
  --zIndex-homeCategoryInspirationLinkOverlay: 2;
  --zIndex-homeSocialPostsOverlay: 2;
  --zIndex-pdpListingPhotoOverlay: 2;
  --zIndex-categoriesBetaTag: 2;
  --zIndex-socialIcons: 3;
  --zIndex-bottomSheetNonPortal: 4;
  --zIndex-bookingConfiguratorFooterBackdrop: 4;
  --zIndex-bookingConfiguratorFooterContainer: 5;
  --zIndex-footerContainer: 5;
  --zIndex-homeHeroOverlays: 5;
  --zIndex-searchDesktopMapToggler: 5;
  --zIndex-oldAvatarVerified: 5;
  --zIndex-sliderArrow: 5;
  --zIndex-reservationDetailsArrowContainer: 9;
  --zIndex-searchArrowContainer: 9;
  --zIndex-managementRadioAfter: 10;
  --zIndex-homeHeroChildren: 10;
  --zIndex-trackingNotificationBanner: 1001;
  --zIndex-onboardingPhotoSortingContainer: 10;
  --zIndex-onboardingAmenityContainer: 10;
  --zIndex-tooltip: 10;
  --zIndex-verificationFirstScreenVerified: 10;
  --zIndex-onboardingPhotoSortingHandle: 20;
  --zIndex-onboardingPhotoImageCard: 50;
  --zIndex-homeHeaderLinksExpander: 50;
  --zIndex-searchCarouselDotsContainer: 50;
  --zIndex-searchCategoriesRow: 51;
  --zIndex-searchBarMobileContainer: 52;
  --zIndex-headerNonOpaque: 56;
  --zIndex-headerOpaque: 58;
  --zIndex-bottomSheetPortal: 59;
  --zIndex-bookingConfiguratorMobile: 60;
  --zIndex-loggedOutDiscountPopupClose: 100;
  --zIndex-loggedOutDiscountPopupBackdrop: 200;
  --zIndex-openInAppContainer: 999;
  --zIndex-weekdayDiscounts: 1000;
  --zIndex-calendar: 1301;
  --zIndex-imageGalleryBackdrop: 1301;
  --zIndex-imageGalleryDismissBackdrop: 1302;
  --zIndex-imageGalleryContent: 1303;
  --zIndex-imageGalleryClose: 1304;
  --zIndex-onboardingLoadingContainer: 9999;
  --zIndex-pageLoader: 9999;
  --zIndex-popper: 10000;
  --zIndex-bookingConfiguratorFieldSelected: 10002;
  --zIndex-tutorialTooltipBackdrop: 10003;
  --zIndex-tutorialTooltip: 10004;
  --zIndex-searchMapMarkerSelected: 100000;
  --zIndex-debugOverrideSheet: 100000;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_1d17b8';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(https://assets.swimply.com/_next/static/media/bde16c1724335d95-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_1d17b8';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(https://assets.swimply.com/_next/static/media/8a9e72331fecd08b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_1d17b8';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(https://assets.swimply.com/_next/static/media/0610ebff456d6cfc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_1d17b8';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(https://assets.swimply.com/_next/static/media/e3b8d441242e07fb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_1d17b8';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(https://assets.swimply.com/_next/static/media/21ed5661b47f7f6d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Fallback_1d17b8';src: local("Arial");ascent-override: 99.71%;descent-override: 34.82%;line-gap-override: 0.00%;size-adjust: 101.39%
}.__className_1d17b8 {font-family: '__Nunito_1d17b8', '__Nunito_Fallback_1d17b8';font-style: normal
}.__variable_1d17b8 {--font-swimply: '__Nunito_1d17b8', '__Nunito_Fallback_1d17b8'
}

