html {
  margin-top: 0 !important;
}

* {
  font-family: var(--font-swimply), sans-serif;
  box-sizing: border-box;
}

body {
  background: #fff;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  max-width: 100%;
  -webkit-font-smoothing: subpixel-antialiased !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

a {
  text-decoration: none;
  outline: none;
  color: inherit;
}

input {
  -webkit-appearance: none;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

a.ui-slider-handle-snapping {
  -webkit-transition: none;
  -moz-transition: none;
}

.StripeElement {
  border: 1px solid transparent;
}
.StripeElement--focus {
  border: 1px solid #00b2eb;
}
.StripeElement.StripeElement--invalid {
  border: 1px solid #ff0000;
}
@media (max-width: 767px) {
  .ratingNew svg {
    width: 35px !important;
    height: 35px !important;
  }
}

.open-in-app-container {
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid lightgrey;
}
